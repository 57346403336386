import { ListItemButton } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Pagination from '@mui/material/Pagination';
import Typography from '@mui/material/Typography';

import ListItemText from '@mui/material/ListItemText';

import React from 'react';
import useSWR from 'swr';
import { fetcherAxios } from '../../../helpers/fetcher-axios';
import { currencyFormat, dexLogos } from '../../../helpers/helpers';
import { ShortLiquidityPool } from '../../../interfaces/liquidity-pools.interface';

const LiquidityPoolsSummary = (props: any) => {
    const { data } = useSWR<ShortLiquidityPool[]>(`pools/token/${props.token}`, fetcherAxios)
    const [page, setPage] = React.useState(1);

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    const itemsPerPage = 5;

    if (!data) {
        return (<Box />);
    }

    if (Array.isArray(data) && data.length === 0) {
        return (<Box />);
    }

    return (

        <Box
            sx={(theme) => ({
                display: 'flex',
                gap: '20px',
                [theme.breakpoints.down("sm")]: {
                    flexDirection: 'column-reverse',
                }
            })}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start',
                    bgcolor: 'background.paper',
                    borderRadius: '12px',
                    boxShadow: 1,
                    fontWeight: 'bold',
                    p: 2,
                    flex: 1,

                }}
            >
                <Typography variant="h6" sx={{
                    alignSelf: 'center',
                }}>Top Liquidity Pools</Typography>

                <List sx={{
                    width: 1,
                    height: 1
                }}>
                    {data.slice((page - 1) * itemsPerPage, page * itemsPerPage).map((lp: ShortLiquidityPool) => (
                        <ListItemButton alignItems="center" href={`/pools/ethereum/${lp.address}`}>
                            <ListItemAvatar>
                                <Avatar src={dexLogos[lp.dex]} />
                            </ListItemAvatar>

                            <ListItemText
                                primary={lp.name}
                                secondary={lp.tvl ? currencyFormat(lp.tvl, 0) : ''}
                            />
                        </ListItemButton>

                    ))}
                </List>
                <Divider />
                <Box sx={{
                    alignSelf: 'center',
                }}>
                    <Pagination
                        count={Math.ceil(data.length / itemsPerPage)}
                        page={page}
                        onChange={handleChange}
                        defaultPage={1}
                        siblingCount={0}
                    />
                </Box>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    bgcolor: 'background.paper',
                    borderRadius: '12px',
                    boxShadow: 1,
                    p: 2,
                    flex: 1,
                }}
            >

                <Typography variant="h6">What is a liquidity pool?</Typography>
                <Typography variant="body1" sx={{ mt: 1, p: 3 }}>
                    In decentralized finance (DeFi), a liquidity pool is a collection of assets that are pooled together and made available to be borrowed or lent. Liquidity pools are typically used to provide liquidity to decentralized exchanges (DEXs) and other DeFi platforms, allowing users to buy and sell assets without the need for a centralized exchange.
                    <br /><br />
                    Liquidity pools play a key role in DeFi, as they provide the necessary liquidity for decentralized exchanges and other DeFi platforms to function. They also offer an opportunity for users to earn passive income by contributing assets to the pool and sharing in the fees and rewards generated by trades.
                </Typography>
            </Box>
        </Box>
    );
}

export default LiquidityPoolsSummary;